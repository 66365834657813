'use client'

import dynamic from 'next/dynamic'
import { GlobalLoader } from '@/components/global-loader'

export const DynamicLoginSection = dynamic(
  () => import('./login-section').then((comp) => comp.LoginSection),
  {
    ssr: false,
    loading: () => <GlobalLoader />,
  }
)
